import { observer } from 'mobx-react-lite'
import { Button, Card, Form, Row } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { PERMISSIONS } from 'e2e-utils'

import { useStore } from '@hooks'
import { FANBEE_DOMAIN, FANBEE_LINK_TYPE, IMAGE_TYPE } from '@epic-front/common'
import { deleteImage, IDeleteImageResponse } from '@epic-front/common/src/api/users-api/other/deleteImage'
import { IFanbeeProfile } from '@epic-front/common/src/models/fanbee/FanbeeProfile.model'
import { FanbeeLinkList, IFanbeeLinkList } from '@epic-front/common/src/models'
import { IImageBasic } from '@epic-front/common/src/models/general/ImageBasic.model'

import { ImageUpload } from '@blocks'
import { UpRightArrow } from '@vectopus/atlas-icons-react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { removeFanbeeLink } from '@epic-front/common/src/api/assets-api/assets/fanbee/removeFanbeeLink'
import FanbeePlatformsLinks from './FanbeeSocialPlatformsLinks'
import WidgetTitle from '../titles/WidgetTitle'
import FanbeeLinksTable from './FanbeeLinksTable'
import FanbeeLinkModal from './FanbeeLinkModal'

type IProps = {
  fanbeeProfile: IFanbeeProfile
}

const FanbeeProfileUpdate = ({ fanbeeProfile }: IProps): JSX.Element => {
  const { auth } = useStore()

  const [showModal, setShowModal] = useState<boolean>(false)

  const [links] = useState<IFanbeeLinkList>(FanbeeLinkList.create())

  useEffect(() => {
    links.setUserUuidFilter(fanbeeProfile.userUuid || '')
    links.setTypeFilter(FANBEE_LINK_TYPE.PROFILE)
    links.load()
  }, [])

  const handleProfilePictureUpload = async (imageData: IImageBasic) => {
    fanbeeProfile.setProfileImage(null)
    fanbeeProfile.setProfileImageUuid(imageData.uuid ?? '')
    await fanbeeProfile.update()
  }

  const handleProfilePictureDelete = async () => {
    const profilePictureImageUuid = fanbeeProfile.getProfileUrlUuid()
    if (profilePictureImageUuid) {
      const resp: IDeleteImageResponse = await deleteImage(IMAGE_TYPE.FANBEE_PROFILE, profilePictureImageUuid)
      if (resp?.data.data?.deleteImage) {
        fanbeeProfile.setProfileImage(null)
        fanbeeProfile.setProfileImageUuid('')
        fanbeeProfile.update()
      }
    }
  }

  const handleBannerPictureUpload = async (imageData: IImageBasic) => {
    fanbeeProfile.setBannerImage(null)
    fanbeeProfile.setBannerImageUuid(imageData.uuid ?? '')
    await fanbeeProfile.update()
  }

  const handleAddLink = async (path: string) => {
    fanbeeProfile.setSlug(path)
    await fanbeeProfile.update()
    await links.load()

    setShowModal(false)
  }

  const handleRemoveLink = async (linkUuid: string) => {
    try {
      await removeFanbeeLink(linkUuid)
      await links.load()
    } catch {
      toast.error('There was a problem while trying to remove the link.')
    }
  }

  const handleBannerPictureDelete = async () => {
    const bannerPictureImageUuid = fanbeeProfile.getBannerUrlUuid()
    if (bannerPictureImageUuid) {
      const resp: IDeleteImageResponse = await deleteImage(IMAGE_TYPE.FANBEE_BANNER, bannerPictureImageUuid)
      if (resp?.data.data?.deleteImage) {
        fanbeeProfile.setBannerImage(null)
        fanbeeProfile.setBannerImageUuid('')
        fanbeeProfile.update()
      }
    }
  }

  const isClient = auth.hasPermission(PERMISSIONS.FANBEE.MANAGE_SELF)

  return (
    <Row>
      <div className="col-12 col-xxl-8 col-xxxl-9">
        <Card>
          <Card.Header>
            <WidgetTitle
              title="Profile Details"
              supportingText="Edit your profile details: Name, Slug and Bio."
              reverseOrder
              actions={
                <Link
                  target="_blank"
                  className="btn-lg shadow-sm btn btn-light"
                  to={`${FANBEE_DOMAIN}/${fanbeeProfile.slug}`}
                >
                  Preview Profile
                  <UpRightArrow weight="bold" />
                </Link>
              }
            />
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-name`} className="mb-3">
                <h5 className="mb-1">Name</h5>
                <p className="text-gray-500 mb-1">This will be the main title of your page.</p>
                <Form.Control
                  type="text"
                  disabled={fanbeeProfile.loading}
                  defaultValue={fanbeeProfile.name || ''}
                  onChange={event => fanbeeProfile.setName(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-slug`} className="mb-3">
                <h5 className="mb-1">URL / Slug</h5>
                <p className="text-gray-500 mb-1">
                  The extension added to the end of {FANBEE_DOMAIN}/<b>xxxxxx</b>
                </p>
                <Form.Control
                  type="text"
                  disabled={isClient || fanbeeProfile.loading}
                  defaultValue={fanbeeProfile.slug || ''}
                  onChange={event => fanbeeProfile.setSlug(event.target.value)}
                />
                {isClient && (
                  <Form.Text className="text-gray-500">
                    <p className="my-1">Please contact our operations team for the change of the URL.</p>
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-bio`} className="mb-3">
                <h5 className="mb-1">Bio / Information</h5>
                <p className="text-gray-500 mb-1">Information or bio description about your brand or yourself.</p>
                <Form.Control
                  as="textarea"
                  rows={5}
                  disabled={fanbeeProfile.loading}
                  defaultValue={fanbeeProfile.bio || ''}
                  onChange={event => fanbeeProfile.setBio(event.target.value)}
                />
              </Form.Group>
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-urls`}>
                <h5 className="mb-1">Urls</h5>
                <p className="text-gray-500 mb-1">All the active links to your Fanbee profile.</p>
                <Button
                  size="lg"
                  type="button"
                  variant="info"
                  className="mb-3 px-3"
                  disabled={fanbeeProfile.loading}
                  onClick={() => setShowModal(true)}
                >
                  Add new URL
                </Button>
                <FanbeeLinksTable links={links.list} handleRemoveLink={handleRemoveLink} />
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Footer>
            <Button
              size="lg"
              className="btn"
              variant="success"
              disabled={fanbeeProfile.loading}
              onClick={() => fanbeeProfile.update()}
            >
              <i className="uil-check" />
              Update details
            </Button>
          </Card.Footer>
        </Card>
        <FanbeePlatformsLinks fanbeeProfile={fanbeeProfile} />
      </div>
      <div className="col-12 col-xxl-4 col-xxxl-3">
        <Card>
          <Card.Header>
            <WidgetTitle title="Avatar image" supportingText="The image shown at the top of your page." reverseOrder />
          </Card.Header>
          <Card.Body>
            <ImageUpload
              type={IMAGE_TYPE.FANBEE_PROFILE}
              previewUrl={fanbeeProfile.profileImage?.downloadUrl ?? undefined}
              minWidth={250}
              minHeight={250}
              onDelete={handleProfilePictureDelete}
              onUploadComplete={handleProfilePictureUpload}
            />
            <div className="alert alert-info mt-3 mb-0 small text-center">
              Square proportions & minimum size: <b>250 x 250px</b>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <WidgetTitle
              title="Background image"
              supportingText="This image will appear in the top part of the page."
              reverseOrder
            />
          </Card.Header>
          <Card.Body>
            <ImageUpload
              type={IMAGE_TYPE.FANBEE_BANNER}
              previewUrl={fanbeeProfile.bannerImage?.downloadUrl ?? undefined}
              minWidth={1280}
              minHeight={640}
              onDelete={handleBannerPictureDelete}
              onUploadComplete={handleBannerPictureUpload}
            />
            <div className="alert alert-info mt-3 mb-0 small text-center">
              Landscape proportions & minimum size: <b>1280 x 640px</b>
            </div>
          </Card.Body>
        </Card>
      </div>

      <FanbeeLinkModal
        open={showModal}
        loading={fanbeeProfile.loading}
        onClose={() => setShowModal(false)}
        onHandleAddLink={handleAddLink}
      />
    </Row>
  )
}

export default observer(FanbeeProfileUpdate)

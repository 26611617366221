import { useEffect, useState } from 'react'
import { useStore } from '@hooks'
import { Modal } from '@blocks'
import { observer } from 'mobx-react-lite'
import { USER_LABEL } from '@epic-front/common'
import { getSupportEmail } from '@epic-front/common/src/helpers'

const AutoReleaseClaimConfirmModal = (): JSX.Element => {
  const { auth, site } = useStore()
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  useEffect(() => {
    if (auth.user?.hasLabelByName(USER_LABEL.MOTION_ARRAY_OPTIN_MODAL)) {
      setShowConfirmModal(true)
    }
  }, [])

  const handleConfirm = async (value: boolean) => {
    try {
      await auth.user?.updateMotionArrayTC({ accepted: value })
      auth.user?.removeLabelByName(USER_LABEL.MOTION_ARRAY_OPTIN_MODAL)
    } catch (e) {
      console.error(e)
    } finally {
      setShowConfirmModal(false)
    }
  }

  return (
    <Modal
      isOpen={showConfirmModal}
      title="Motion Array Copyright Management"
      onHide={() => {
        setShowConfirmModal(false)
      }}
      size="lg"
      buttons={
        <>
          <button
            type="button"
            className="btn btn-gray-400 px-2"
            disabled={auth.loading || auth.user?.loading}
            onClick={() => handleConfirm(false)}
          >
            <i className="uil-times" />
            Deny
          </button>
          <button
            type="button"
            className="btn btn-success px-2"
            disabled={auth.loading || auth.user?.loading}
            onClick={() => handleConfirm(true)}
          >
            <i className="uil-check" />
            Accept
          </button>
        </>
      }
    >
      <div className="alert alert-warning">
        <p className="m-0">We are delighted to announce we have streamlined claim release&apos;s with Motion Array. </p>
        <p className="m-0">
          Moving forward, clients who have their music available on Motion Array can opt into have claims auto-released,
          this process will also include authentication of licenses by Motion Array.
        </p>
        <p className="m-0">Please accept the terms and conditions below. </p>
        <p className="m-0">
          If you have any queries about the new service, please reach out to us at:{' '}
          <a href={`mailto:${getSupportEmail()}`}>{getSupportEmail()}</a>
        </p>
      </div>

      <div className="alert alert-info mb-0 gap-2 p-2 overflow-y-auto">
        <h5 className="text-center mb-4">TERMS AND CONDITIONS</h5>

        <h6>1. Agreement to Grant Rights</h6>
        <p>
          {`By using our services, you (the "Client") agree to grant ${site?.name} (the "Company")
          the rights to release any claims on third-party content using your music, upon receiving a request to do the
          same from Motion Array.`}
        </p>

        <h6>2. No Obligation to Notify</h6>
        <p>
          The Company shall not be required to check with you before releasing the claim, or to intimate you after such
          a claim has been released. This includes, but is not limited to, any communication before or after the claim
          release.
        </p>

        <h6>3. No Responsibility for Verification</h6>
        <p>
          The Company shall not bear any responsibility for verifying the type of usage or the legitimacy of the
          uploader being a Motion Array user. This means that the Company will act solely based on the request from
          Motion Array without conducting any further investigations or verifications.
        </p>

        <h6>4. Limitation of Liability</h6>
        <p>
          {`In the event that the video URL sent by Motion Array for claim release is erroneous, does not use music that
          is part of their service, or the usage is of a nature not covered by Motion Array, the Client shall not hold
          the Company liable for any damages or consequences. The Client understands and accepts that errors or
          miscommunications from Motion Array are outside the Company's control.`}
        </p>

        <h6>5. Opt-Out Procedure</h6>
        <p>
          If the Client stops being part of Motion Array, or for any other reason, the Client can opt-out of this
          agreement by sending written instructions to <a href={`mailto:${getSupportEmail()}`}>{getSupportEmail()}</a> .
          The opt-out will take effect upon receipt and confirmation by the Company.
        </p>

        <h6>6. Jurisdiction</h6>
        <p>
          These terms and conditions shall be governed by and construed in accordance with the laws of England and
          Wales. Any disputes arising out of or in connection with these terms shall be subject to the exclusive
          jurisdiction of the courts of England and Wales.
        </p>

        <h6>7. Amendments</h6>
        <p>
          The Company reserves the right to amend these terms and conditions at any time. Any such amendments will be
          effective immediately upon being posted on the Company’s website or otherwise communicated to the Client. The
          Client’s continued use of the Company’s services following such amendments constitutes acceptance of the new
          terms and conditions.
        </p>

        <h6>8. Entire Agreement</h6>
        <p>
          These terms and conditions constitute the entire agreement between the Client and the Company regarding the
          subject matter herein and supersede all prior or contemporaneous agreements, understandings, and
          representations, whether written or oral, relating to such subject matter.
        </p>
        <p>
          By agreeing to these terms, the Client acknowledges that they have read, understood, and agree to be bound by
          all the provisions contained herein.
        </p>
      </div>
    </Modal>
  )
}

export default observer(AutoReleaseClaimConfirmModal)

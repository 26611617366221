import { Instance, types } from 'mobx-state-tree'

import { ImageBasic } from '../general/ImageBasic.model'
import { FanbeeUrl } from './FanbeeUrl.model'

export const FanbeeProfileBasic = types.model({
  uuid: types.maybeNull(types.string),
  userUuid: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  bio: types.maybeNull(types.string),
  profileImage: types.maybeNull(ImageBasic),
  bannerImage: types.maybeNull(ImageBasic),
  platformUrls: types.array(FanbeeUrl),
})

export type IFanbeeProfileBasic = Instance<typeof FanbeeProfileBasic>

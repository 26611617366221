import { SERVICE_NAME } from '../../../../constants'

import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

export type IRemoveFanbeeLinkResponse = IGenericResponse<'removeFanbeeLink', boolean>

export async function removeFanbeeLink(linkUuid: string): Promise<IRemoveFanbeeLinkResponse> {
  const query = `
    mutation removeFanbeeLink($linkUuid: String!) {
        removeFanbeeLink(linkUuid: $linkUuid)
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { linkUuid })) as IRemoveFanbeeLinkResponse
}

import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { IFanbeeProfileBasic } from '../../../models/fanbee/FanbeeProfileBasic.model'

import { fanbeeUrlFragment } from '../fragments'

export type ICreateFanbeeUrlProps = {
  userUuid: string
  userSlug: string
  platformSlug: string
  url: string
}

export type ICreateFanbeeUrlResponse = IGenericResponse<'createFanbeeUrl', IFanbeeProfileBasic>

export async function createFanbeeUrl(
  data: ICreateFanbeeUrlProps,
  fragment: string = fanbeeUrlFragment
): Promise<ICreateFanbeeUrlResponse> {
  const query = `
  mutation createFanbeeUrl($data: FanbeePlatformUrlInput!) {
      createFanbeeUrl(data: $data) {
          ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { data })) as ICreateFanbeeUrlResponse
}

import { Link } from 'react-router-dom'
import { ELITE_ALLIANCE, ELITE_ALLIANCE_MUSIC, EPIC_ELITE, PLATFORM } from '@epic-front/common'
import { getSiteLogoLight } from '../../helpers/images'
import NotFoundSVG from '../../assets/images/svgs/404'
import { useStore } from '../../hooks'

const ErrorPageNotFound = (): JSX.Element => {
  const { auth } = useStore()

  return (
    <>
      <div
        className="d-flex align-items-center flex-column justify-content-center vh-100 position-relative gap-3"
        style={{ zIndex: 2 }}
      >
        <img src={getSiteLogoLight()} alt="" height="72" />
        <NotFoundSVG />
        <div className="text-center text-white">
          <h1 className="fw-bold display-1">Page not found</h1>
          <p className="lead">The page you’re looking for doesn’t exist.</p>
          <Link
            className="btn btn-primary btn-lg mt-3"
            to={{
              pathname:
                auth.isAuth && [EPIC_ELITE, ELITE_ALLIANCE, ELITE_ALLIANCE_MUSIC].includes(PLATFORM || '')
                  ? '/dashboard'
                  : '/',
            }}
          >
            Return Home
          </Link>
        </div>
      </div>
      <div className="login-background">
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </>
  )
}

export default ErrorPageNotFound

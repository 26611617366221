// Platform envs
export const PLATFORM = process.env.REACT_APP_PLATFORM
export const PLATFORM_NAME = process.env.REACT_APP_PLATFORM_NAME
export const PLATFORM_SHORT_NAME = process.env.REACT_APP_PLATFORM_SHORT_NAME
export const ENV = process.env.REACT_APP_ENV || 'dev'
export const VERSION = process.env.REACT_APP_VERSION || '-'
export const GA_ID = process.env.REACT_APP_GA_ID || '' // google analytics id

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY || ''

export const MAX_RETRIES = 1
export const TIME_BETWEEN_RETRIES = 2000 // in ms
export const SESSION_DURATION = 7 * 24 * 3600 * 1000 // 7 days in ms
export const ERROR_BOUNDARY_REFRESH_TIMEOUT = 1000 * 60 * 30 // 30 minutes in ms
export const ADMIN_SHIFT_DURATION_HOURS = 8
export const VERSION_CHECK_DURATION = 300 * 1000 // 5 minutes in ms

export const URL_USERS_API = process.env.REACT_APP_USER_API_URL
if (!URL_USERS_API) {
  throw new Error(`Users api url not defined.`)
}

export const URL_PAYMENTS_API = process.env.REACT_APP_PAYMENTS_API_URL
if (!URL_PAYMENTS_API) {
  throw new Error(`Users api url not defined.`)
}

export const URL_ASSETS_API = process.env.REACT_APP_ASSETS_API_URL
if (!URL_ASSETS_API) {
  throw new Error(`Assets api url not defined.`)
}

export const URL_LOGS_API = process.env.REACT_APP_LOGS_API_URL
if (!URL_LOGS_API) {
  throw new Error(`Logs api url not defined.`)
}

export const SITE_TOKEN = process.env.REACT_APP_SITE_TOKEN
if (!SITE_TOKEN) {
  throw new Error(`Site token not defined.`)
}

export enum EnvLocations {
  DEV = 'dev',
  STG = 'stg',
  PROD = 'prod',
}

export enum SERVICE_NAME {
  USERS = 'users-api',
  ASSETS = 'assets-api',
  PAYMENTS = 'payments-api',
  LOGS = 'logs-api',
}

export enum LOG_LEVEL {
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
  INFO = 'info',
  DEBUG = 'debug',
}

export enum QUERY_PARAM_TYPE {
  DISPUTED_CLAIMS = 'DisputedClaims',
}

type TLogLevelBadgeColorType = {
  [key: string]: string
}

export const LOGS_LEVEL_BADGE_COLOR: TLogLevelBadgeColorType = {
  log: 'primary',
  info: 'info',
  debug: 'secondary',
  warn: 'warning',
  error: 'danger',
}

// Payment options
export const PAYMENT_METHOD_SLUG_OFX = 'banktransfer_ofx'
export const PAYMENT_METHOD_SLUG_BANKTRANSFER = 'banktransfer'
export const PAYMENT_METHOD_SLUG_TRANSFERWISE = 'transferwise'
export const PAYMENT_METHOD_SLUG_PAYONEER = 'payoneer'
export const PAYMENT_METHOD_SLUG_PAYPAL = 'paypal'
export const PAYMENT_METHOD_SLUG_WORLDFIRST = 'worldfirst'

// Sites
export const SONGMATE = 'songmate'
export const SONGPITCH = 'songpitch'
export const ADMIN = 'admin'
export const EPIC_ELITE = 'epic-elite'
export const ELITE_ALLIANCE = 'elite-alliance'
export const ELITE_ALLIANCE_MUSIC = 'elite-alliance-music'

// Site slugs
export const SITE_SLUG = {
  EPIC_ELITE: 'ee',
  ELITE_ALLIANCE: 'ea',
  ELITE_ALLIANCE_MUSIC: 'mea',
  SONGMATE: 'sm',
  SONGPITCH: 'sp',
  FANBEE: 'fb',
  ADMIN: 'adm',
  SONGPROMO: 'sgpr',
  SONGCENTRAL: 'ml',
}

// Cms slugs
export const CMS_SLUG = {
  EA_GM: 'EA GM',
  MEA_EE_PUB: 'MEA EE PUB',
  MEA: 'MEA',
  EE: 'EE',
  EE_PUB: 'EE PUB',
  EA_GMA: 'EA GMA',
}

export enum YOUTUBE_CMS {
  EA = 'ea',
  EE = 'ee',
  MEA = 'mea',
  PUB = 'pub',
}

export const INVITATION_SITES = [
  SITE_SLUG.EPIC_ELITE,
  SITE_SLUG.ELITE_ALLIANCE,
  SITE_SLUG.ELITE_ALLIANCE_MUSIC,
  SITE_SLUG.SONGMATE,
]

// Query params filters names
export const TEXT_QUERY_NAME = 'search'
export const MULTIPLE_IDS_QUERY_NAME = 'multipleIds'
export const USER_QUERY_NAME = 'user'
export const ADMIN_QUERY_NAME = 'user'
export const SITES_QUERY_NAME = 'sites'
export const CMS_QUERY_NAME = 'cms'
export const LABELS_QUERY_NAME = 'labels'
export const ROLES_QUERY_NAME = 'roles'
export const STATUS_QUERY_NAME = 'status'
export const REQUESTED_STATUS_QUERY_NAME = 'requested_status'
export const INTERNAL_STATUS_QUERY_NAME = 'internal_status'
export const CLIENT_STATUS_QUERY_NAME = 'client_status'
export const STATUSES_QUERY_NAME = 'statuses'
export const STORES_QUERY_NAME = 'stores'
export const STATES_QUERY_NAME = 'states'
export const METHOD_QUERY_NAME = 'method'
export const MONTH_QUERY_NAME = 'month'
export const PAGE_QUERY_NAME = 'page'
export const PERPAGE_QUERY_NAME = 'perPage'
export const ELIGIBLE_QUERY_NAME = 'eligible'
export const IS_COVER_QUERY_NAME = 'isCover'
export const IS_ENABLED_QUERY_NAME = 'enabled'
export const IS_CREATED_BY_US_QUERY_NAME = 'isCreatedByUs'
export const IS_UNASSIGNED_QUERY_NAME = 'isUnassigned'
export const IS_ASSIGNED_QUERY_NAME = 'isAssigned'
export const COMPOSITION_QUERY_NAME = 'composition'
export const ASSIGNMENT_QUERY_NAME = 'assignment'
export const SPLIT_QUERY_NAME = 'split'
export const IS_EARLY_QUERY_NAME = 'isEarly'
export const SORT_DIRECTION_QUERY_NAME = 'dir'
export const SORT_COL_QUERY_NAME = 'sort'
export const IS_INCOMPLETE_METHOD_QUERY_NAME = 'incomplete'
export const DO_NOT_PAY_QUERY_NAME = 'doNotPay'
export const TOKEN_QUERY_NAME = 'K'
export const PITCHES_QUERY_NAME = 'pitches'
export const PITCH_QUERY_NAME = 'pitch'
export const GENRE_QUERY_NAME = 'genre'
export const ASSET_QUERY_NAME = 'asset'
export const ORDER_QUERY_NAME = 'order'
export const CHANNEL_QUERY_NAME = 'channel'
export const COUNTRY_QUERY_NAME = 'country'
export const TYPES_QUERY_NAME = 'types'
export const PLATFORM_QUERY_NAME = 'platform'
export const ACTIVE_REFERENCE_QUERY_NAME = 'reference'
export const SERVICE_QUERY_NAME = 'service'
export const SECTION_QUERY_NAME = 'section'
export const IS_PRIVATE_VIDEO_QUERY_NAME = 'isPrivateVideo'
export const IS_TAKE_ACTION_EXPIRED = 'isTakeActionExpired'
export const TAKE_ACTION_BY = 'takeActionBy'
export const APPEAL_EXPLANATION_QUERY_NAME = 'appealExplanation'
export const DISPUTE_NOTE_QUERY_NAME = 'disputeNote'
export const DISPUTE_REASON_QUERY_NAME = 'disputeReason'
export const HISTORY_EVENT_TYPE_QUERY_NAME = 'historyEventType'
export const AFTER_DATE_QUERY_NAME = 'afterDate'
export const UPC_QUERY_NAME = 'upc'
export const CONTRACT_STATUS_QUERY_NAME = 'contractStatus'
export const PAYMENT_STATUS_QUERY_NAME = 'paymentStatus'
export const TEMPLATES_TITLES_QUERY_NAME = 'templateTitles'
export const RELEASE_UUID_QUERY_NAME = 'releaseUuid'
export const UUID_QUERY_NAME = 'releaseUuid'
export const GROUP_QUERY_NAME = 'group'
export const LICENSE_REQUESTED_QUERY_NAME = 'licenseRequested'
export const FROM_DATE_QUERY_NAME = 'fromDate'
export const TO_DATE_QUERY_NAME = 'toDate'
export const START_DATE_QUERY_NAME = 'startDate'
export const END_DATE_QUERY_NAME = 'endDate'
export const IS_ACTIVE_QUERY_NAME = 'isActive'
export const IS_INACTIVE_QUERY_NAME = 'isInactive'
export const IS_DELETED_QUERY_NAME = 'isDeleted'

// Pagination elements
export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}
export const DEFAULT_PER_PAGE_OPTIONS = [
  {
    text: '10',
    value: 10,
  },
  {
    text: '20',
    value: 20,
  },
  {
    text: '50',
    value: 50,
  },

  {
    text: '100',
    value: 100,
  },
]
export const DEFAULT_PER_PAGE_VALUE = 20

export const DEFAULT_SORT_DIRECTION = SortDirection.ASC

export const MIN_PASSWORD_LENGTH = 6

export const SERVER_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSS[Z]'

export enum USER_PAYMENT_METHOD_STATUS {
  PENDING = 'PENDING',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum USER_PAYMENT_METHOD_INTERNAL_STATUS {
  PENDING = 'PENDING',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
}

export enum EARNING_TYPE {
  REGULAR = 'REGULAR',
  SPONSORSHIP = 'SPONSORSHIP',
  ADJUSTMENT = 'ADJUSTMENT',
  WHT_ADJUSTMENT = 'WHT_ADJUSTMENT',
  SALARY = 'SALARY',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export const EARNING_TYPE_BADGE_COLOR: {
  [property in EARNING_TYPE]: string
} = {
  REGULAR: 'success',
  SPONSORSHIP: 'primary',
  ADJUSTMENT: 'info',
  WHT_ADJUSTMENT: 'info',
  SALARY: 'secondary',
  MISCELLANEOUS: 'dark',
}

export enum INVITATION_STATUS {
  SENT = 'SENT',
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
}

export enum CONTACT_REQUEST_STATUS {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  DECLINED = 'DECLINED',
  SOLVED = 'SOLVED',
}

export enum DOCUMENT_SIGNING_STATUS {
  AWAITING_SIGNATURE = 'awaiting_signature',
  SIGNED = 'signed',
  DECLINED = 'declined',
  NO_NEED = 'no_need',
}

export enum DOCUMENT_STATUS {
  WAITING_APPROVAL = 'waiting_approval',
  PENDING = 'pending',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

export enum TAX_FORM_TEMPLATE_TITLE {
  W9 = 'w9',
  W8BENE = 'w8bene',
  FW8BEN = 'fw8ben',
}
export enum TAX_FORM_ENTITY_TYPE {
  INDIVIDUAL = 'Individual',
  BUSINESS = 'Business',
}

export enum CHANNEL_STATUS_COLOR {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
}
export enum CHANNEL_STATUS {
  UNLINKED = 'UNLINKED',
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export enum CLIENT_TIER {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum ANNOUNCEMENT_TYPES {
  INFO = 'INFO',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
}
export enum ANNOUNCEMENT_STATUS {
  AWAITING = 'AWAITING',
  CURRENTLY = 'CURRENTLY',
  EXPIRED = 'EXPIRED',
}
export const ANNOUNCEMENT_STATUS_BADGE_COLOR = {
  AWAITING: 'warning',
  EXPIRED: 'danger',
  CURRENTLY: 'success',
}

export enum DISTRIBUTION_CONFIG_NAME {
  STATEMENT_DATE = 'statementDate',
}

export const CLIENT_TIER_DESCRIPTION = {
  A: 'A - selects %',
  B: 'B - has either 50/50 or 0/100 ',
  C: 'C - has no revenue share options',
}

export const SIGNATURE_METHOD = {
  SIGN: 'sign',
  UPLOAD: 'upload',
}

export enum DOCUMENT_TYPE {
  CONTRACT = 'contract',
  TAX_FORM = 'tax_form',
}

export enum REVENUE_SPLIT_INVITATION_STATUS {
  PENDING = 'PENDING',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
  ADMIN_DECLINED = 'ADMIN_DECLINED',
  ADMIN_APPROVED = 'ADMIN_APPROVED',
  REMOVAL_REQUESTED = 'REMOVAL_REQUESTED',
}

export enum CHANNEL_WHITELIST_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const YOUTUBE_VIDEO_LINK_PREFIX = 'https://www.youtube.com/watch?v='
export const YOUTUBE_CHANNEL_LINK_PREFIX = 'https://www.youtube.com/channel/'

export enum IMAGE_TYPE {
  FANBEE_PROFILE = 'FanbeeProfile',
  FANBEE_BANNER = 'FanbeeBanner',
  NOTE = 'Note',
  FRONT = 'Front',
  OTHER = 'Other',
  ARTIST = 'Artist',
}

export enum CONTACT_REQUEST_SUBJECTS {
  JOIN = 'JoinRequest',
  CLAIM = 'CopyrightClaim',
  LICENSE = 'LicensePurchase',
  GENERAL = 'General',
  CAREER = 'Career',
}

export enum NOTE_TYPE {
  DEFAULT = 'DEFAULT',
  CLAIM = 'CLAIM',
  FINANCIAL = 'FINANCIAL',
}

export enum USER_GROUP_TYPE {
  TEAMS = 'teams',
  PAYMENT = 'payment',
}

export const USER_GROUP_TYPE_BADGE_COLOR = {
  teams: 'warning',
  payment: 'info',
  default: 'secondary', // this should never happen but just in case
}

export const FANBEE_DOMAIN = 'https://fanb.ee'

export enum FANBEE_SOCIAL_PLATFORMS {
  DISCORD = 'Discord',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TWITTER = 'Twitter',
  WEBSITE = 'Website',
  YOUTUBE = 'Youtube',
}

export enum FANBEE_LINK_TYPE {
  PROFILE = 'Profile',
  RELEASE = 'Release',
}

export enum USER_LABEL {
  MOTION_ARRAY_OPTIN_MODAL = 'Motion Array OptIn Modal',
  MOTION_ARRAY_DENIED = 'Motion Array Denied',
  MOTION_ARRAY_AUTORELEASE = 'Motion Array AutoRelease',
}

export const SUPPORT_EMAIL = {
  SONGMATE: 'support@songmate.com',
  EPIC_ELITE: 'support@epicelite.com',
  ELITE_ALIANCE_MUSIC: 'eam@elitealliance.net',
}

import { ITableColumn, Table } from '@blocks'
import { FANBEE_DOMAIN } from '@epic-front/common'
import { IFanbeeLinkBasic } from '@epic-front/common/src/models'
import { Trash } from '@vectopus/atlas-icons-react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface IFanbeeLinksTableProps {
  links: IFanbeeLinkBasic[]
  handleRemoveLink: (linkUuid: string) => void
}

const FanbeeLinksTable = ({ links, handleRemoveLink }: IFanbeeLinksTableProps): JSX.Element => {
  const linkColumns: ITableColumn<IFanbeeLinkBasic>[] = [
    {
      Header: 'Url',
      accessor: 'path',
      headerCellClasses: 'text-nowrap text-start',
      cellClasses: 'text-start mincol',
      Cell: row => (
        <div className="d-flex gap-1 justify-content-start align-items-center">
          <strong>{row.path}</strong>
          <br />
          <Link target="_blank" className="btn btn-sm btn-light" to={`${FANBEE_DOMAIN}/${row.path}`}>
            {FANBEE_DOMAIN}/{row.path}
            <i className="uil uil-arrow-up-right" />
          </Link>
        </div>
      ),
    },
    {
      Header: 'Redirected',
      accessor: 'redirectTo',
      headerCellClasses: 'text-nowrap text-start',
      cellClasses: 'text-start mincol',
      Cell: row =>
        row.redirectTo ? (
          <div className="d-flex gap-1 justify-content-start align-items-center">
            <Link target="_blank" className="btn btn-sm btn-light" to={`${FANBEE_DOMAIN}/${row.redirectTo}`}>
              {FANBEE_DOMAIN}/{row.redirectTo}
              <i className="uil uil-arrow-up-right" />
            </Link>
          </div>
        ) : (
          <>-</>
        ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      cellClasses: 'actions-column',
      headerCellClasses: 'text-nowrap mincol  text-end',
      Cell: (row: IFanbeeLinkBasic) => (
        <div className="d-flex justify-content-end gap-2">
          <Button variant="danger" onClick={() => handleRemoveLink(row.uuid || '')}>
            <Trash />
          </Button>
        </div>
      ),
    },
  ]

  return (
    <Table<IFanbeeLinkBasic>
      data={links}
      tableHeadClasses="table-dark"
      tableClasses="table-striped"
      columns={linkColumns}
      rowIdentifier={row => row.uuid || ''}
    />
  )
}

export default FanbeeLinksTable

import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'

import { socialPlatforms } from '@epic-front/common/src/api/users-api/fanbee/socialPlatforms'

import { IFanbeeProfile } from '@epic-front/common/src/models/fanbee/FanbeeProfile.model'

import { ITableColumn, Table } from '@blocks'

import { IFanbeeUrl } from '@epic-front/common/src/models/fanbee/FanbeeUrl.model'
import { FANBEE_SOCIAL_PLATFORMS, VALID_URL_REGEX } from '@epic-front/common'
import WidgetTitle from '../titles/WidgetTitle'

const PLACEHOLDER_MAP = {
  [FANBEE_SOCIAL_PLATFORMS.DISCORD]: 'https://discords.com/servers/channelnumber',
  [FANBEE_SOCIAL_PLATFORMS.FACEBOOK]: 'https://www.facebook.com/pagename/',
  [FANBEE_SOCIAL_PLATFORMS.INSTAGRAM]: 'https://www.instagram.com/pagename',
  [FANBEE_SOCIAL_PLATFORMS.TWITTER]: 'https://x.com/pagename',
  [FANBEE_SOCIAL_PLATFORMS.WEBSITE]: 'https://www.pagename.com',
  [FANBEE_SOCIAL_PLATFORMS.YOUTUBE]: 'https://www.youtube.com/@ChannelName',
}

const DEFAULT_PLACEHOLDER = 'Enter platform account name'

type IProps = {
  fanbeeProfile: IFanbeeProfile
}

const FanbeeSocialPlatformsLinks = ({ fanbeeProfile }: IProps): JSX.Element => {
  const [socialPlatformSlugs, setSocialPlatformSlugs] = useState<FANBEE_SOCIAL_PLATFORMS[]>([])

  const [newLinkPlatformSlug, setNewLinkPlatformSlug] = useState<FANBEE_SOCIAL_PLATFORMS>()
  const [newPlatformUrl, setNewPlatformUrl] = useState<string>()

  useEffect(() => {
    const getSocialPlatforms = async () => {
      try {
        const resp = await socialPlatforms()
        if (resp && resp?.data.data?.socialPlatforms) {
          setSocialPlatformSlugs(resp.data.data.socialPlatforms)
        }
      } catch (err) {
        console.error(err)
      }
    }

    getSocialPlatforms()
  }, [fanbeeProfile.userUuid])

  const getPlaceholder = () => {
    if (!newLinkPlatformSlug) {
      return DEFAULT_PLACEHOLDER
    }

    return PLACEHOLDER_MAP[newLinkPlatformSlug] || DEFAULT_PLACEHOLDER
  }

  const handleAddNewPlatformUrl = () => {
    if (!newPlatformUrl || !VALID_URL_REGEX.test(newPlatformUrl)) {
      return
    }

    fanbeeProfile.createFanbeeUrl(newLinkPlatformSlug || '', newPlatformUrl || '')
  }

  const columns: ITableColumn<IFanbeeUrl>[] = [
    {
      Header: 'Platform',
      accessor: 'platformSlug',
    },
    {
      Header: 'Platform Url',
      accessor: 'url',
      headerCellClasses: 'text-center',
      cellClasses: 'text-nowrap text-center',
      Cell: row => (
        <a href={row.url || ''} target="_blank" rel="noreferrer">
          {row.url}
        </a>
      ),
    },
  ]

  return (
    <Card>
      <Card.Header>
        <WidgetTitle
          title="Social Platform links"
          supportingText="Add the links to your Social Networks."
          reverseOrder
        />
      </Card.Header>
      <Card.Body>
        <Form>
          <Row className="mb-2">
            <Col xs={12} sm={4} className="mb-2">
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-platform-slug`}>
                <Form.Label>Platform name:</Form.Label>
                <Select
                  className="react-select p-0 h-100"
                  classNamePrefix="react-select"
                  placeholder="Select platform"
                  options={socialPlatformSlugs.map(platform => ({ label: platform, value: platform }))}
                  onChange={inputPlatform => setNewLinkPlatformSlug(inputPlatform?.label)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} className="mb-2">
              <Form.Group controlId={`${fanbeeProfile.userUuid}-fanbee-platform-url`}>
                <Form.Label>Platform Url:</Form.Label>
                <Form.Control
                  className="input"
                  type="text"
                  placeholder={getPlaceholder()}
                  disabled={fanbeeProfile.loading}
                  defaultValue={newPlatformUrl}
                  onChange={event => setNewPlatformUrl(event.target.value)}
                  isInvalid={newPlatformUrl ? !VALID_URL_REGEX.test(newPlatformUrl) : undefined}
                />
                <Form.Control.Feedback type="invalid" tooltip />
              </Form.Group>
            </Col>
            <Col xs={12} sm={2} className="mb-2 d-flex justify-content-end align-items-end">
              <Button
                variant="success"
                className="w-100"
                disabled={
                  fanbeeProfile.loading ||
                  !newLinkPlatformSlug ||
                  !newPlatformUrl ||
                  !VALID_URL_REGEX.test(newPlatformUrl)
                }
                onClick={() => handleAddNewPlatformUrl()}
              >
                <i className="uil-plus lh-1 fs-5 me-1" />
                Add
              </Button>
            </Col>
          </Row>
        </Form>

        <Table<IFanbeeUrl>
          tableClasses="table table-light table table-striped table-hover border"
          tableHeadClasses="table-dark"
          columns={columns}
          data={fanbeeProfile.platformUrls}
          isLoading={fanbeeProfile.loading}
          rowIdentifier={row => row.uuid || ''}
        />
      </Card.Body>
    </Card>
  )
}

export default observer(FanbeeSocialPlatformsLinks)

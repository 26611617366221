import { SITE_SLUG } from '@epic-front/common'
import { numberFormat } from '@epic-front/common/src/helpers'
import { IUser } from '@epic-front/common/src/models'
import {
  Account,
  AudioWave,
  Chain,
  CogwheelSettingsAccount,
  DashboardPerformance,
  DollarClipboardFile,
  DollarSignCircle,
  FileArrowUp,
  FileShield,
  FolderShield,
  GraphChart,
  MusicNotes,
  VideoPlayerWebsite,
  WebsiteClip,
  XmarkShield,
} from '@vectopus/atlas-icons-react'
import { PERMISSIONS } from 'e2e-utils'
import { observer } from 'mobx-react-lite'
import { SONGMATE_ROUTES } from '../../routes/platformRoutes/songmateRoutes'
import { IMenuItem } from './menu.types'

const MENU_ITEMS_SONGMATE: IMenuItem[] = [
  { key: 'your-dashboard', name: 'Your  Dashboard', isTitle: true },
  {
    IconElement: <DashboardPerformance size={20} className="me-2" />,
    ...SONGMATE_ROUTES.DASHBOARD,
  },

  {
    key: 'epic-elite-dashboard',
    name: 'Distribution',
    isTitle: true,
    permissionsAnd: [PERMISSIONS.CLIENT_FEATURE.LIST_RELEASES],
  },

  {
    IconElement: <GraphChart size={20} className="me-2" />,
    ...SONGMATE_ROUTES.ANALYTICS_DISTRIBUTION,
  },

  {
    IconElement: <MusicNotes size={20} className="me-2" />,
    ...SONGMATE_ROUTES.RELEASES,
  },

  {
    IconElement: <AudioWave size={20} className="me-2" />,
    ...SONGMATE_ROUTES.AUT_CREATE,
  },

  {
    IconElement: <DollarSignCircle size={20} className="me-2" />,
    ...SONGMATE_ROUTES.DISTRIBUTION_EARNINGS,
  },

  {
    key: 'fanbee',
    name: 'Fanbee',
    isTitle: true,
    permissionsOr: [PERMISSIONS.FANBEE.MANAGE_SELF],
  },
  {
    IconElement: <Account size={20} className="me-2" />,
    ...SONGMATE_ROUTES.FANBEE_PROFILE,
  },
  {
    IconElement: <Chain size={20} className="me-2" />,
    ...SONGMATE_ROUTES.FANBEE_RELEASES,
  },

  { key: 'youtube-title', name: 'Youtube', isTitle: true },

  {
    IconElement: <GraphChart size={20} className="me-2" />,
    ...SONGMATE_ROUTES.ANALYTICS_CONTENT_ID,
  },

  {
    IconElement: <XmarkShield size={20} className="me-2" />,
    ...SONGMATE_ROUTES.CLAIMS_CONTENT_ID,
    LineEndElement: observer(({ store }) => (
      <>
        {store?.activeClaims?.pagination.totalItems && store?.activeClaims?.pagination.totalItems > 0 ? (
          <span className="badge bg-warning claim-menu-badge">
            {numberFormat(store?.activeClaims?.pagination.totalItems)}
          </span>
        ) : (
          <> </>
        )}
      </>
    )),
  },

  {
    IconElement: <FileShield size={20} className="me-2" />,
    ...SONGMATE_ROUTES.ASSETS_CONTENT_ID,
  },
  {
    IconElement: <FileArrowUp size={20} className="me-2" />,
    ...SONGMATE_ROUTES.AUDIO_UPLOAD_CONTENT_ID,
    shouldShow: (user: IUser) => user?.hasSite(SITE_SLUG.ELITE_ALLIANCE_MUSIC),
  },
  {
    IconElement: <FolderShield size={20} className="me-2" />,
    ...SONGMATE_ROUTES.AUDIO_LIST,
    shouldShow: (user: IUser) => user?.hasSite(SITE_SLUG.ELITE_ALLIANCE_MUSIC),
  },

  {
    key: 'channels',
    name: 'Channels',
    IconElement: <VideoPlayerWebsite size={20} className="me-2" />,
    permissionsOr: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    children: [SONGMATE_ROUTES.CHANNEL_LIST, SONGMATE_ROUTES.VIDEOS],
  },
  {
    IconElement: <WebsiteClip size={20} className="me-2" />,
    ...SONGMATE_ROUTES.CHANNEL_WHITELISTING,
  },
  {
    IconElement: <DollarSignCircle size={20} className="me-2" />,
    ...SONGMATE_ROUTES.EARNINGS_CONTENT_ID,
  },

  { key: 'payments-title', name: 'Payments', isTitle: true },

  {
    IconElement: <DollarClipboardFile size={20} className="me-2" />,
    ...SONGMATE_ROUTES.MONTHLY_REPORT,
  },
  {
    IconElement: <CogwheelSettingsAccount size={20} className="me-2" />,
    ...SONGMATE_ROUTES.TAX_FORMS,
  },
  // {
  //   ...SONGMATE_ROUTES.CONTENTYPEPAGE,
  //   IconElement: <File size={20} className="me-2" />,
  // },
  // {
  //   ...SONGMATE_ROUTES.DESIGN,
  //   IconElement: <GraphChart size={20} className="me-2" />,
  // },
]

export default MENU_ITEMS_SONGMATE

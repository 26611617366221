import { toast } from 'react-toastify'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Button, Form } from 'react-bootstrap'

import { Modal } from '@blocks'
import { FANBEE_LINK_PATH_REGEX } from '@epic-front/common'

interface IFanbeeLinkModalProps {
  open: boolean
  loading: boolean
  onClose: () => void
  onHandleAddLink: (path: string) => void
}

const FanbeeLinkModal = ({ open, loading, onClose, onHandleAddLink }: IFanbeeLinkModalProps): JSX.Element => {
  const [path, setPath] = useState<string>()

  const handleAddLink = async () => {
    if (!path) {
      return
    }

    if (!FANBEE_LINK_PATH_REGEX.test(path)) {
      toast.error('Link can only contain lowercase and uppercase letters, numbers and dashes')
      return
    }

    onHandleAddLink(path)
  }

  return (
    <Modal
      isOpen={open}
      title="Add new url for your release"
      size="lg"
      onHide={onClose}
      buttons={
        <>
          <Button variant="gray-400" onClick={onClose}>
            <i className="uil-times" />
            Cancel
          </Button>
          <Button variant="success" disabled={loading} onClick={handleAddLink}>
            <i className="uil-check" />
            Add
          </Button>
        </>
      }
    >
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Url</Form.Label>
          <Form.Control
            type="text"
            disabled={loading}
            placeholder="release-title-by-artist"
            onChange={e => setPath(e.target.value)}
          />
        </Form.Group>
      </Form>
    </Modal>
  )
}

export default observer(FanbeeLinkModal)

import { PERMISSIONS } from 'e2e-utils'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

import { commonPublicAccountRoutes } from '../commonPublicAccountRoutes'
import { commonPublicRoutes } from '../commonPublicRoutes'

import { commonPrivateRoutes } from '../commonPrivateRoutes'
import { ISiteRouteList, RouteType } from '../types'

/**
 * LAZY LOAD ALL THE PAGES
 */
const Dashboard = lazy(() => import('../../pages/overview/DashboardEEPage'))
const PaymentSelect = lazy(() => import('../../pages/payments/PaymentSelect/PaymentSelectPage'))
const ChannelPage = lazy(() => import('../../pages/contentId/ChannelPage'))
const MyAccountPage = lazy(() => import('../../pages/profile/MyAccountPage'))
const AssetListPage = lazy(() => import('../../pages/contentId/AssetListPage'))
const AssetPage = lazy(() => import('../../pages/contentId/AssetPage'))
const YoutubeEarningsPage = lazy(() => import('../../pages/contentId/YoutubeEarningsPage'))
const DistributionAnalyticsPage = lazy(() => import('../../pages/analytics/DistributionAnalyticsPage'))
const YoutubeAnalyticsPage = lazy(() => import('../../pages/analytics/YoutubeAnalyticsPage'))
const ChannelVideoListPage = lazy(() => import('../../pages/channelVideos/ChannelVideoListPage'))
const MonthlyReportPage = lazy(() => import('../../pages/payments/MonthlyReportPage'))
const EpicEliteHomePage = lazy(() => import('../../pages/landing-epic-elite/EpicEliteHomePage'))
const EpicEliteTeamPage = lazy(() => import('../../pages/landing-epic-elite/EpicEliteTeamPage'))
const EpicEliteContactPage = lazy(() => import('../../pages/landing-epic-elite/EpicEliteContactPage'))
const EpicEliteCareersPage = lazy(() => import('../../pages/landing-epic-elite/EpicEliteCareersPage'))
const RegisterInvitation = lazy(() => import('../../pages/account/RegisterInvitation'))
const DistributionEarningsPage = lazy(() => import('../../pages/release/distribution/DistributionEarningsPage'))
const ListReleasesPage = lazy(() => import('../../pages/release/listRelease/ReleaseListPage'))
const ViewReleasePage = lazy(() => import('../../pages/release/viewRelease/ViewReleasePage'))
const TaxFormPage = lazy(() => import('../../pages/payments/TaxFormPage/TaxFormPage'))
const ClaimListClientPage = lazy(() => import('../../pages/contentId/claims/ClaimListClientPage'))
const DisputedClaimsClientPage = lazy(() => import('../../pages/contentId/claims/DisputedClaimsClientPage'))
const AudioUploadToolPage = lazy(() => import('../../pages/aut/AutPage'))
const ChannelListPage = lazy(() => import('../../pages/contentId/ChannelListPage'))
const EpicEliteSDRPage = lazy(() => import('../../pages/landing-epic-elite/EpicEliteSDRPage'))
// const EpicEliteJobStrategyManagerPage = lazy(
//   () => import('../../pages/landing-epic-elite/EpicEliteJobStrategyManagerPage')
// )
const ManageChannelWhitelistingPage = lazy(
  () => import('../../pages/channelWhitelisting/ManageChannelWhitelistingPage')
)

// root routes
const routes: ISiteRouteList = {
  ROOT_HOME: {
    // this should be rendered a public route but listed in the AUTH_PROTECTED ones
    key: 'ROOT_HOME',
    name: 'Home',
    url: '/',
    exact: true,
    element: <Navigate to="/home" />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  HOME: {
    key: 'HOME',
    url: '/home',
    name: 'Home',
    element: <EpicEliteHomePage />,
    routeType: RouteType.PUBLIC,
  },
  TEAM: {
    url: '/team',
    name: 'Team',
    key: 'TEAM',
    element: <EpicEliteTeamPage />,
    routeType: RouteType.PUBLIC,
  },
  CONTACT: {
    key: 'CONTACT',
    url: '/contact',
    name: 'Contact',
    element: <EpicEliteContactPage />,
    routeType: RouteType.PUBLIC,
  },
  CAREERS: {
    key: 'CAREERS',
    url: '/careers',
    name: 'Careers',
    element: <EpicEliteCareersPage />,
    routeType: RouteType.PUBLIC,
  },
  //
  // START OF JOB PAGES
  //
  JOBPAGE_SDR: {
    key: 'JOBPAGE_SDR',
    url: '/sales-development-representative',
    name: 'sales-development-representative',
    element: <EpicEliteSDRPage />,
    routeType: RouteType.PUBLIC,
  },
  // JOBPAGE_STRATEGY_MANAGER: {
  //   key: 'JOBPAGE_STRATEGY_MANAGER',
  //   url: '/strategy-manager',
  //   name: 'strategy-manager',
  //   element: <EpicEliteJobStrategyManagerPage />,
  //   routeType: RouteType.PUBLIC,
  // },
  REGISTER_INVITE: {
    key: 'REGISTER_INVITE',
    url: '/account/register-invite',
    name: 'Register',
    element: <RegisterInvitation />,
    routeType: RouteType.PUBLIC,
  },
  // auth protected routes
  DASHBOARD: {
    key: 'DASHBOARD',
    url: '/dashboard',
    name: 'Dashboard',
    element: <Dashboard />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL: {
    key: 'CHANNEL',
    url: '/channel/:id',
    name: 'Channel',
    element: <ChannelPage />,
    permissionsOr: [PERMISSIONS.CHANNEL.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MY_ACCOUNT: {
    key: 'MY_ACCOUNT',
    url: '/account/my-account',
    name: 'My Account',
    element: <MyAccountPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  ASSETS_CONTENT_ID: {
    key: 'ASSETS_CONTENT_ID',
    url: '/content-id/assets',
    name: 'Assets',
    element: <AssetListPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL_LIST: {
    url: '/channels',
    name: 'Channels',
    element: <ChannelListPage />,
    permissionsAnd: [PERMISSIONS.CHANNEL.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'CHANNEL_LIST',
  },
  ANALYTICS_CONTENT_ID: {
    key: 'ANALYTICS_CONTENT_ID',
    url: '/content-id/analytics',
    name: 'Analytics',
    element: <YoutubeAnalyticsPage />,
    permissionsAnd: [PERMISSIONS.ASSET.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CLAIMS_CONTENT_ID: {
    key: 'CLAIMS_CONTENT_ID',
    url: '/content-id/claims',
    name: 'Claims',
    element: <ClaimListClientPage />,
    permissionsOr: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  EARNINGS_CONTENT_ID: {
    key: 'EARNINGS_CONTENT_ID',
    url: '/content-id/earnings',
    name: 'Earnings',
    element: <YoutubeEarningsPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  DISPUTED_CLAIMS_CONTENT_ID: {
    url: '/content-id/disputed-claims',
    name: 'Disputed Claims',
    element: <DisputedClaimsClientPage />,
    permissionsAnd: [PERMISSIONS.CLAIM.LIST_SELF, PERMISSIONS.CLAIM.RELEASE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'DISPUTED_CLAIMS_CONTENT_ID',
  },
  VIDEOS: {
    key: 'VIDEOS',
    url: '/channel-videos/',
    name: 'Channel Videos',
    element: <ChannelVideoListPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MONTHLY_REPORT: {
    key: 'MONTHLY_REPORT',
    url: '/payments/monthly-report',
    name: 'Payment Reports',
    element: <MonthlyReportPage />,
    permissionsOr: [PERMISSIONS.EARNING.REPORT_EXPORT, PERMISSIONS.EARNING.LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL_WHITELISTING: {
    key: 'CHANNEL_WHITELISTING',
    url: '/channel-whitelisting',
    name: 'Channel Whitelisting',
    element: <ManageChannelWhitelistingPage />,
    permissionsOr: [PERMISSIONS.CHANNEL_WHITELISTING.LIST_SELF, PERMISSIONS.CHANNEL_WHITELISTING.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  ANALYTICS_DISTRIBUTION: {
    key: 'ANALYTICS_DISTRIBUTION',
    url: '/distribution-analytics',
    name: 'Analytics',
    element: <DistributionAnalyticsPage />,
    permissionsAnd: [PERMISSIONS.CLIENT_FEATURE.LIST_RELEASES],
    routeType: RouteType.AUTH_PROTECTED,
  },
  DISTRIBUTION_EARNINGS: {
    key: 'DISTRIBUTION_EARNINGS',
    url: '/distribution-earnings',
    name: 'Earnings',
    element: <DistributionEarningsPage />,
    permissionsAnd: [PERMISSIONS.ASSET.DISTRIBUTION_EARNING_LIST_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  ASSET_CONTENT_ID: {
    key: 'ASSETS_CONTENT_ID',
    url: '/content-id/asset/:id',
    name: 'Asset',
    element: <AssetPage />,
    permissionsOr: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.ASSET.MANAGE_SELF],
    routeType: RouteType.AUTH_PROTECTED,
  },
  RELEASES: {
    url: '/release/list',
    name: 'Releases',
    key: 'RELEASES',
    element: <ListReleasesPage />,
    routeType: RouteType.AUTH_PROTECTED,
    permissionsAnd: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.CLIENT_FEATURE.LIST_RELEASES],
  },

  AUT_CREATE: {
    url: '/aut',
    name: 'Audio Upload Tool',
    key: 'AUT_CREATE',
    element: <AudioUploadToolPage />,
    permissionsAnd: [
      PERMISSIONS.ASSET.LIST_SELF,
      PERMISSIONS.CLIENT_FEATURE.MANAGE_RELEASES,
      PERMISSIONS.CLIENT_FEATURE.CREATE_AUDIO_RELEASES,
    ],
    routeType: RouteType.AUTH_PROTECTED,
  },
  AUT_EDIT: {
    url: '/aut/:id',
    name: 'Audio Upload Tool',
    element: <AudioUploadToolPage />,
    permissionsAnd: [
      PERMISSIONS.ASSET.LIST_SELF,
      PERMISSIONS.CLIENT_FEATURE.MANAGE_RELEASES,
      PERMISSIONS.CLIENT_FEATURE.CREATE_AUDIO_RELEASES,
    ],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'UPDATE_RELEASE',
  },
  VIEW_RELEASE: {
    url: '/release/view/:id',
    name: 'View Release',
    element: <ViewReleasePage />,
    permissionsAnd: [PERMISSIONS.ASSET.LIST_SELF, PERMISSIONS.CLIENT_FEATURE.LIST_RELEASES],
    routeType: RouteType.AUTH_PROTECTED,
    key: 'VIEW_RELEASE',
  },
  PAYMENT_SELECT: {
    key: 'PAYMENT_SELECT',
    url: '/payment-select',
    name: 'Payment Select',
    element: <PaymentSelect />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  TAX_FORMS: {
    key: 'TAX_FORMS',
    url: '/tax-forms',
    name: 'Payment Setup',
    element: <TaxFormPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
}

export const EPIC_ELITE_ROUTES = {
  ...commonPublicRoutes,
  ...routes,
  ...commonPrivateRoutes(routes),
  ...commonPublicAccountRoutes,
}

import { cast, detach, flow, Instance, types } from 'mobx-state-tree'

import { IPagination, Pagination } from '../../general/Pagination.model'

import {
  fanbeeLinks,
  IGetFanbeeLinksProps,
  IGetFanbeeLinksResponse,
} from '../../../api/assets-api/assets/fanbee/getFanbeeLinks'

import { FanbeeLinkBasic } from './FanbeeLinkBasic.model'

export const FanbeeLinkList = types
  .model({
    list: types.array(FanbeeLinkBasic),
  })
  .volatile(() => ({
    loading: false,
    userUuidFilter: '',
    releaseUuidFilter: '',
    typeFilter: '',
    pagination: Pagination.create({ totalItems: 0 }),
  }))
  .actions(self => ({
    setUserUuidFilter(userUuid: string) {
      self.userUuidFilter = userUuid
    },
    setReleaseUuidFilter(userUuid: string) {
      self.userUuidFilter = userUuid
    },
    setTypeFilter(type: string) {
      self.typeFilter = type
    },
    setPagination(pagination: IPagination) {
      self.pagination = pagination
    },
    load: flow(function* () {
      try {
        self.loading = true

        const props: IGetFanbeeLinksProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.typeFilter && { type: self.typeFilter }),
            ...(self.userUuidFilter && { userUuid: self.userUuidFilter }),
            ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),
          },
        }

        const resp: IGetFanbeeLinksResponse = yield fanbeeLinks(props)

        if (resp && resp.data.data?.fanbeeLinks) {
          detach(self.list)
          self.list = cast(resp.data.data.fanbeeLinks.fanbeeLinks)
          self.pagination.setTotalItems(resp.data.data.fanbeeLinks.total)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IFanbeeLinkList = Instance<typeof FanbeeLinkList>

import { Instance, types } from 'mobx-state-tree'

import { FANBEE_LINK_TYPE } from '../../../constants'

export const FanbeeLinkBasic = types.model({
  uuid: types.maybeNull(types.string),
  type: types.maybeNull(types.enumeration(Object.values(FANBEE_LINK_TYPE))),
  path: types.maybeNull(types.string),
  userUuid: types.maybeNull(types.string),
  releaseUuid: types.maybeNull(types.string),
  redirectTo: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  artist: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),
})

export type IFanbeeLinkBasic = Instance<typeof FanbeeLinkBasic>

import { applySnapshot, cast, flow, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'
import { deactivateUser, IDeactivateUserResponse } from '../../api/users-api/users/deactivateUser'
import { editUser, IEditUserProps, IEditUserResponse } from '../../api/users-api/users/editUser'
import { getUser, IGetUserResponse } from '../../api/users-api/users/getUser'
import { ISetClientTireResponse, setClientTier } from '../../api/users-api/users/setClientTier'
import { ISetIsEarlyPercentageResponse, setIsEarlyPercentage } from '../../api/users-api/users/setIsEarlyPercentage'
import { ISetMainUserSiteResponse, setMainUserSite } from '../../api/users-api/users/setMainUserSite'
import { ISetParentClientResponse, setParentClient } from '../../api/users-api/users/setParentClient'
import { IToggleDoNotPayResponse, toggleDoNotPay } from '../../api/users-api/users/toggleDoNotPay'
import { IToggleIsEarlyResponse, toggleIsEarly } from '../../api/users-api/users/toggleIsEarly'
import { IToggleLabelResponse, toggleLabel } from '../../api/users-api/users/toggleLabel'
import { IToggleRoleResponse, toggleRole } from '../../api/users-api/users/toggleRole'
import { IToggleSiteResponse, toggleSite } from '../../api/users-api/users/toggleSite'
import { IToggleUserGroupResponse, toggleUserGroup } from '../../api/users-api/users/toggleUserGroup'
import { CLIENT_TIER } from '../../constants'
import { defaultDateFormat } from '../../helpers'

import {
  editUserNameAddress,
  IEditUserNameAddressProps,
  IEditUserNameAddressResponse,
} from '../../api/users-api/users/editUserNameAddress'
import {
  IUpdateBrandSponsorshipPercentagesProps,
  IUpdateBrandSponsorshipPercentagesResponse,
  updateBrandSponsorshipPercentages,
} from '../../api/users-api/users/updateBrandSponsorshipPercentages'
import { UserPaymentMethod } from '../payment/UserPaymentMethod.model'
import { IUserPaymentMethodBasic } from '../payment/UserPaymentMethodBasic.model'
import { UserBasic } from './UserBasic.model'
import {
  IUpdateMotionArrayTCProps,
  IUpdateMotionArrayTCResponse,
  updateMotionArrayTC,
} from '../../api/users-api/users/updateMotionArrayTC'

export const User = UserBasic.views(self => ({
  get firstLastName() {
    if (!self.firstName && !self.lastName) {
      return '--'
    }
    return `${self.firstName} ${self.lastName}`
  },
  get firstLastDisplayName() {
    if (!self.firstName && !self.lastName && !self.displayName) {
      return '--'
    }
    return `${self.firstName || ''} ${self.lastName || ''}${self.displayName ? ' - ' : ''}${self.displayName || ''}`
  },
  get userLabel(): string {
    if (!self) return ''

    let label = self.firstName || ''
    if (self.lastName) {
      label += label.length > 0 ? ' ' : ''
      label += self.lastName
    }

    if (self.displayName) {
      label += label.length > 0 ? ' ' : ''
      label += `- ${self.displayName}`
    }

    label += self.isChild ? ' (C)' : ''
    label += self.isParent ? ' (P)' : ''

    if (self.deletedAt) {
      label += ` (Deleted on ${defaultDateFormat(self.deletedAt)})`
    }

    return label
  },
  get hasInternalRole(): boolean {
    return self.roles.map(role => role.isInternal || false).reduce((prev, acc) => acc || prev, false)
  },
}))
  .views(self => ({
    get userLabelWithEmail(): string {
      return `${self.userLabel} (${self.email})`
    },
  }))
  .volatile(() => ({
    loading: false,
    updating: false,
    userPaymentMethod: UserPaymentMethod.create(),
  }))
  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true
        const resp: IGetUserResponse = yield getUser({ uuid: self.uuid })
        if (resp && resp.data.data) {
          applySnapshot(self, resp.data.data.user)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    removeLabelByName(labelName: string) {
      const newLabels = self.labels.filter(label => label.name !== labelName)
      applySnapshot(self, { ...self, labels: newLabels })
    },
    hasLabelByName(labelName: string): boolean {
      return self.labels.map(label => label.name).includes(labelName)
    },
    updateMotionArrayTC: flow(function* (props: IUpdateMotionArrayTCProps) {
      try {
        self.loading = true
        const resp: IUpdateMotionArrayTCResponse = yield updateMotionArrayTC(props)

        if (resp?.data.data?.updateMotionArrayTC.uuid) {
          toast.success('Motion Array Copyright Management Terms and Conditions successfully set!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))
  .actions(self => ({
    toggleRole: flow(function* (roleUuid: string) {
      try {
        const resp: IToggleRoleResponse = yield toggleRole({ userUuid: self.uuid, roleUuid })
        if (resp && resp.data.data?.toggleRole) {
          applySnapshot(self, { ...self, roles: resp.data.data.toggleRole.roles })
          toast.success('User role was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    toggleSite: flow(function* (siteUuid: string) {
      try {
        const resp: IToggleSiteResponse = yield toggleSite({ userUuid: self.uuid, siteUuid })
        if (resp && resp.data.data?.toggleUserSite) {
          applySnapshot(self, { ...self, userSites: resp.data.data.toggleUserSite.userSites })
          toast.success('User site was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    toggleLabel: flow(function* (labelUuid: string) {
      try {
        const resp: IToggleLabelResponse = yield toggleLabel({ labelUuid, userUuid: self.uuid })
        if (resp && resp.data.data?.toggleLabel) {
          applySnapshot(self, { ...self, labels: resp.data.data.toggleLabel.labels })
          toast.success('User label was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    toggleUserGroup: flow(function* (userGroupUuid: string) {
      try {
        const resp: IToggleUserGroupResponse = yield toggleUserGroup({ userGroupUuid, userUuid: self.uuid })
        if (resp && resp.data.data?.toggleUserGroup) {
          applySnapshot(self, { ...self, userGroups: resp.data.data.toggleUserGroup.userGroups })
          toast.success('User group was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    setMainSite: flow(function* (siteUuid: string) {
      try {
        const resp: ISetMainUserSiteResponse = yield setMainUserSite({ siteUuid, userUuid: self.uuid })
        if (resp && resp.data.data?.setMainUserSite) {
          applySnapshot(self, { ...self, userSites: resp.data.data.setMainUserSite })
          toast.success('User main site was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    setClientTier: flow(function* (tier: CLIENT_TIER | null) {
      try {
        const resp: ISetClientTireResponse = yield setClientTier({ userUuid: self.uuid, clientTier: tier })
        if (resp && resp.data.data?.setClientTier) {
          applySnapshot(self, { ...self, clientTier: tier })
          toast.success('User client tier was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    toggleIsEarly: flow(function* (state: boolean) {
      try {
        const resp: IToggleIsEarlyResponse = yield toggleIsEarly({ userUuid: self.uuid })
        if (resp && resp.data.data?.toggleIsEarly) {
          applySnapshot(self, { ...self, isEarly: state })

          // when isEarly is set to true, the message will be sent from setIsEarlyPercentage
          if (state === false) {
            toast.success('Early payment was successfully removed!')
          }
        }
      } catch (err) {
        console.error(err)
      }
    }),
    toggleDoNotPay: flow(function* (state: boolean) {
      try {
        self.loading = true
        const resp: IToggleDoNotPayResponse = yield toggleDoNotPay(self.uuid)
        if (resp && resp.data.data?.toggleDoNotPay) {
          applySnapshot(self, { ...self, doNotPay: state })

          if (state === true) {
            toast.success('Do Not Pay flag successfully set')
          } else {
            toast.success('Do Not Pay flag successfully cleared')
          }
        }
        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    setIsEarlyPercentage: flow(function* (percentage: number) {
      try {
        const resp: ISetIsEarlyPercentageResponse = yield setIsEarlyPercentage({
          userUuid: self.uuid,
          isEarlyPercentage: percentage,
        })
        if (resp && resp.data.data?.setIsEarlyPercentage) {
          applySnapshot(self, { ...self, isEarlyPercentage: percentage })
          toast.success('Early payment percentage was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),

    setParentClient: flow(function* (parentUuid: string | null) {
      try {
        const resp: ISetParentClientResponse = yield setParentClient({ userUuid: self.uuid, parentUuid })
        if (resp && resp.data.data?.setParentClient) {
          applySnapshot(self, { ...self, parentClientUuid: resp.data.data.setParentClient.parentClientUuid })
          toast.success('Parent was successfully set!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    clearParent: flow(function* () {
      try {
        const resp = yield setParentClient({ userUuid: self.uuid, parentUuid: null })
        if (resp && resp.data.data?.setParentClient) {
          applySnapshot(self, { ...self, parentClientUuid: resp.data.data.setParentClient.parentClientUuid })
          toast.success('Parent was successfully removed!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    deactivateUser: flow(function* () {
      try {
        const resp: IDeactivateUserResponse = yield deactivateUser({ userUuid: self.uuid })
        if (resp && resp.data.data?.deactivateUser) {
          toast.success('User deactivated!')
        }
      } catch (err) {
        console.error(err)
      }
    }),
    setUserPaymentMethod(method: IUserPaymentMethodBasic) {
      self.userPaymentMethod = cast(method)
    },
    updateBrandSponsorship: flow(function* (data: {
      outboundSponsorshipPercentage: number | null
      inboundSponsorshipPercentage: number | null
    }) {
      try {
        self.updating = true

        const variables: IUpdateBrandSponsorshipPercentagesProps = {
          userUuid: self.uuid || '',

          outboundSponsorshipPercentage: data.outboundSponsorshipPercentage || null,
          inboundSponsorshipPercentage: data.inboundSponsorshipPercentage || null,
        }
        const resp: IUpdateBrandSponsorshipPercentagesResponse = yield updateBrandSponsorshipPercentages(variables)
        if (resp && resp.data.data?.updateBrandSponsorshipPercentages) {
          self.inboundSponsorshipPercentage =
            resp.data.data.updateBrandSponsorshipPercentages.inboundSponsorshipPercentage
          self.outboundSponsorshipPercentage =
            resp.data.data.updateBrandSponsorshipPercentages.outboundSponsorshipPercentage
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.updating = false
      }
    }),

    editUser: flow(function* (data: {
      firstName?: string
      lastName?: string
      displayName?: string
      billingName?: string
      addressLine1?: string
      addressLine2?: string
      postCode?: string
      countryCode?: string
      email?: string
    }) {
      try {
        self.updating = true

        const variables: IEditUserProps = {
          userUuidToEdit: self.uuid || '',
          data: {
            firstName: data.firstName?.trim() || '',
            lastName: data.lastName?.trim(),
            billingName: data.billingName?.trim(),
            addressLine1: data.addressLine1?.trim(),
            addressLine2: data.addressLine2?.trim(),
            postCode: data.postCode?.trim(),
            countryCode: data.countryCode?.trim(),
            email: data.email?.trim(),
            displayName: data.displayName?.trim(),
          },
        }

        const resp: IEditUserResponse = yield editUser(variables)

        if (resp && resp.data.data?.editUser) {
          applySnapshot(self, resp.data.data.editUser)
          toast.success('Profile successfully updated!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.updating = false
      }
    }),
    editSelf: flow(function* (data: {
      firstName?: string
      lastName?: string
      displayName?: string
      billingName?: string
      addressLine1?: string
      addressLine2?: string
      postCode?: string
      countryCode?: string
      email?: string
    }) {
      try {
        self.updating = true

        const variables: IEditUserNameAddressProps = {
          data: {
            firstName: data.firstName?.trim() || '',
            lastName: data.lastName?.trim(),
            billingName: data.billingName?.trim(),
            addressLine1: data.addressLine1?.trim(),
            addressLine2: data.addressLine2?.trim(),
            postCode: data.postCode?.trim(),
            countryCode: data.countryCode?.trim(),
            displayName: data.displayName?.trim(),
          },
        }

        const resp: IEditUserNameAddressResponse = yield editUserNameAddress(variables)

        if (resp && resp.data.data?.editUserNameAddress) {
          applySnapshot(self, resp.data.data.editUserNameAddress)
          toast.success('Profile successfully updated!')
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.updating = false
      }
    }),
  }))

export type IUser = Instance<typeof User>

import { applySnapshot, flow, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { IFanbeeProfileResponse, fanbeeProfile } from '../../api/users-api/fanbee/fanbeeProfile'
import { IUpdateFanbeeProfileResponse, updateFanbeeProfile } from '../../api/users-api/fanbee/updateFanbeeProfile'

import { createFanbeeUrl, ICreateFanbeeUrlResponse } from '../../api/users-api/fanbee/createFanbeeUrl'
import { IImageBasic } from '../general/ImageBasic.model'
import { FanbeeProfileBasic } from './FanbeeProfileBasic.model'

export const FanbeeProfile = FanbeeProfileBasic.volatile(() => ({
  loading: false,
}))
  .volatile(() => ({
    profileImageUuid: '',
    bannerImageUuid: '',
  }))
  .views(self => ({
    getProfileUrlUuid() {
      if (self.profileImage?.uuid) {
        return self.profileImage.uuid
      }
      if (self.profileImageUuid.length > 0) {
        return self.profileImageUuid
      }
      return null
    },
    getBannerUrlUuid() {
      if (self.bannerImage?.uuid) {
        return self.bannerImage.uuid
      }
      if (self.bannerImageUuid.length > 0) {
        return self.bannerImageUuid
      }
      return null
    },
  }))
  .actions(self => ({
    setSlug(slug: string) {
      self.slug = slug
    },
    setName(name: string) {
      self.name = name
    },
    setBio(bio: string) {
      self.bio = bio
    },
    setProfileImage(img: IImageBasic | null) {
      self.profileImage = img
    },
    setBannerImage(img: IImageBasic | null) {
      self.bannerImage = img
    },
    setProfileImageUuid(profileImageUuid: string) {
      self.profileImageUuid = profileImageUuid
    },
    setBannerImageUuid(bannerImageUuid: string) {
      self.bannerImageUuid = bannerImageUuid
    },
  }))
  .actions(self => ({
    load: flow(function* (userUuid: string) {
      try {
        self.loading = true

        const resp: IFanbeeProfileResponse = yield fanbeeProfile(userUuid)
        if (resp && resp.data.data?.fanbeeProfile) applySnapshot(self, resp.data.data?.fanbeeProfile)

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    update: flow(function* () {
      try {
        self.loading = true

        const resp: IUpdateFanbeeProfileResponse = yield updateFanbeeProfile({
          uuid: self.uuid || '',
          userUuid: self.userUuid || '',
          slug: self.slug || '',
          name: self.name || '',
          bio: self.bio || '',
          profileImage: self.getProfileUrlUuid(),
          bannerImage: self.getBannerUrlUuid(),
        })

        if (resp && resp.data.data?.updateFanbeeProfile) {
          applySnapshot(self, resp.data.data?.updateFanbeeProfile)
          toast.success('Profile successfully updated!')
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    createFanbeeUrl: flow(function* (platformSlug: string, url: string) {
      try {
        self.loading = true

        const resp: ICreateFanbeeUrlResponse = yield createFanbeeUrl({
          userUuid: self.userUuid || '',
          userSlug: self.slug || '',
          platformSlug,
          url,
        })

        if (resp && resp.data.data?.createFanbeeUrl) {
          const updatedUuid = resp.data.data.createFanbeeUrl.uuid
          const updatedIndex = self.platformUrls.findIndex(pltUrl => pltUrl.uuid === updatedUuid)

          const updatedSelf = self
          if (updatedIndex === -1) {
            updatedSelf.platformUrls.push(resp.data.data?.createFanbeeUrl)
          } else {
            updatedSelf.platformUrls.splice(updatedIndex, 1, resp.data.data.createFanbeeUrl)
          }

          applySnapshot(self, updatedSelf)
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))

export type IFanbeeProfile = Instance<typeof FanbeeProfile>

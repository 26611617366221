import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { IFanbeeProfile, FanbeeProfile } from '@epic-front/common/src/models/fanbee/FanbeeProfile.model'

import { LoadingWrapper } from '@blocks'

import { IUserBasic } from '@epic-front/common/src/models/users/UserBasic.model'
import FanbeeProfileUpdate from './FanbeeProfileUpdate'

type IProps = {
  user: IUserBasic
}

const FanbeeCard = ({ user }: IProps): JSX.Element => {
  const [fanbeeProfile] = useState<IFanbeeProfile>(FanbeeProfile.create())

  useEffect(() => {
    if (user.uuid) {
      fanbeeProfile.load(user.uuid)
    }
  }, [])

  if (!user.uuid) {
    return <></>
  }

  return (
    <LoadingWrapper isLoading={fanbeeProfile.loading}>
      <FanbeeProfileUpdate fanbeeProfile={fanbeeProfile} />
    </LoadingWrapper>
  )
}

export default observer(FanbeeCard)

import { FANBEE_LINK_TYPE, SERVICE_NAME } from '../../../../constants'

import { IFanbeeLinkBasic } from '../../../../models/assets/releases/FanbeeLinkBasic.model'

import { IGenericResponse } from '../../../api.types'
import clientWrapper from '../../../clientWrapper'

import { fanbeeLinkFragment } from '../../fragmentsAssets'

export type IAddFanbeeLinkResponse = IGenericResponse<'addFanbeeLink', IFanbeeLinkBasic>

export type IAddFanbeeLinkProps = {
  type: FANBEE_LINK_TYPE
  path: string
  userUuid?: string
  releaseUuid?: string
}

export async function addFanbeeLink(
  input: IAddFanbeeLinkProps,
  fragment: string = fanbeeLinkFragment
): Promise<IAddFanbeeLinkResponse> {
  const query = `
    mutation addFanbeeLink($input: FanbeeLinkInput!) {
        addFanbeeLink(input: $input) {
            ${fragment}
        }
    }
  `
  return (await clientWrapper(SERVICE_NAME.ASSETS, query, { input })) as IAddFanbeeLinkResponse
}
